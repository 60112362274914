@tailwind base;
@tailwind components;
@tailwind utilities;



@keyframes backgroundTransition {
    0% {
      background-image: url('./assets/images/animate-logo.svg');
    }
    50% {
      background-image: url('./assets/images/animate-logo.svg');
    }
    100% {
      background-image: url('./assets/images/animate-logo.svg'); 
    }
  }